<template>
    <div>
        <div class="mainList">

            <div class="mainList_content">
                <div class="mainList_content_operation">

                    <div class="mainList_content_operation-l">
                        <el-button type="danger" icon="el-icon-delete" v-if="permission(['DBBACKUP_DEL'])" size="small" @click="del()">批量删除</el-button>

                        <el-button size="small" icon="el-icon-document-copy" type="primary" @click="backups" :loading="backupsLoading">备份</el-button>
                    </div>

                    <div class="mainList_content_operation-c">
                        <div class="item">
                            <div class="label">备份时间</div>
                            <el-date-picker
                                v-model="parameter.condition.createDate"
                                size="small"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                        <el-button class="item" type="primary" size="small" icon="el-icon-search" @click="pageChangeHandler(1)">搜索</el-button>
                    </div>

                    <div class="mainList_content_operation-r">
                        <div class="item icon iconfont iconrefresh" @click="getData"></div>
                        <el-popover class="item" placement="bottom" v-model="tableSettingVisible" popper-class="tableColumnSettingPopover">
                            <TableColumnSetting v-model="tableColumnData"></TableColumnSetting>
                            <div slot="reference" class="item icon iconfont iconliebiaoshezhi"></div>
                        </el-popover>
                    </div>

                </div>
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable" :stripe="elTable.stripe" :border="elTable.border" :data="tableData" tooltip-effect="dark" height="string" @sort-change="sortChange" v-loading="loading" header-cell-class-name="meterHeader">
                        <el-table-column type="selection" align="center" width="55" fixed="left"></el-table-column>
                        <el-table-column label="序号" type="index" align="center" width="70"></el-table-column>

                        <el-table-column v-for="n in tableColumnData" v-if="n.isShow" :fixed="n.fixed" align="center" :prop="n.prop" :label="n.label" :sortable="n.sortable">
                            <template slot-scope="scope">
                                <template v-if="n.filter">
                                    {{  n.filter(scope.row[n.prop])  }}
                                </template>
                                <template v-else>
                                    {{  scope.row[n.prop]  }}
                                </template>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" fixed="right"  align="center" width="80" v-if="permission(['DBBACKUP_DEL'])">
                            <template slot-scope="scope">
                                <el-button class="del" type="text" v-if="permission(['DBBACKUP_DEL'])" @click="del(scope)">删除</el-button>
                            </template>
                        </el-table-column>

                    </el-table>
                    <el-pagination
                        @current-change="pageChangeHandler"
                        @size-change="handleSizeChange"
                        :current-page="parameter.pageNumber"
                        :page-size="parameter.pageSize"
                        prev-text="上一页"
                        next-text="下一页"
                        :total="parameter.total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mixinsList from '@/mixins/list'
import {exportLog} from "@/api/controller/admin/loginLog";
import {SERVICEADDRESS} from '@/config'
import {backUp, findByPage} from "@/api/controller/admin/dbBackUp";
export default {
    components: {
        TableColumnSetting: () => import("@/components/TableColumnSetting.vue"),
    },
    mixins: [mixinsList],
    data() {
        return {
            api:{
                findPage:this.$api['admin/dbBackUp'].findByPage,
                del:this.$api['admin/dbBackUp'].del,
            },
            tableColumnData:[
                {prop:'createDate',label:'备份时间',isShow:true,sortable:false,fixed:false,},
                {prop:'fileName',label:'文件名',isShow:true,sortable:false,fixed:false,},
                {prop:'backFileUrl',label:'文件路劲',isShow:true,sortable:false,fixed:false,},
            ],
            backupsLoading:false,
        };
    },
    watch:{
        createDateinterval: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                if(newVal){
                    this.parameter.condition.startTime = newVal[0];
                    this.parameter.condition.endTime = newVal[1];
                }else {
                    this.parameter.condition.startTime = '';
                    this.parameter.condition.endTime = '';
                }
            }
        },
    },
    created() {
        this.getData();
    },
    methods: {
        backups(){
            this.backupsLoading = false;
            this.$api['admin/dbBackUp'].backUp().then(res => {
                this.backupsLoading = false;
                this.pageChangeHandler(1);
                this.$message.success("操作成功！");
            }).catch(err => {
                console.log(err);
                this.backupsLoading = false;
            });
        }
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";

</style>
